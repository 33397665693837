// 1.import
import axios from 'axios';
import router from '@/router';

var isPro = process.env.NODE_ENV === 'production'

var httpUrl = "https://www.phromon.net"
// var httpUrl = "http://www.phromon.net/"
// var httpUrl = "http://ht.phromon.net/"


var root =isPro?httpUrl+'/api.php/':'/dpc/api.php/'
// root ='http://ziruisheng.aguola.com/api.php/'

// root ='http://ht.phromon.net/api.php/'

axios.defaults.timeout = 5000; //请求超时5秒
// axios.defaults.withCredentials = true
axios.defaults.baseURL = root


//http request 拦截器
axios.interceptors.request.use(
	config => {
		config.headers = {
			'Content-Type':'application/json'
		}
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

axios.interceptors.response.use(
	response => {
		if (response.data.code === 4003) {
			Toast({
				mes: '您没有权限操作！',
				timeout: 1500,
				callback: () => {
					router.go(-1);
				}
			});
			return false;
		}
		return response.data
	},
	err => {
		if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
			Toast({
				mes: '网络异常，连接超时...',
				timeout: 1500
			});
		}
		return Promise.reject(err)
	}
)



function includes(arr, str) {
	return arr.map(item => item.trim().toLowerCase()).includes(str);
}
function filterParams(value) {
	if (!equalType(value, Object)) {
		return value;
	}
	const obj = JSON.parse(JSON.stringify(value));
	Object.keys(obj).forEach((key) => {
		const value = obj[key];
		if (Object.is(value, null) || Object.is(value, undefined)) { // 移除null、undefined
			delete obj[key];
		} else if (equalType(value, String)) { // 字串去除空格
			obj[key] = value.trim();
		} else if (equalType(value, Object)) { // 对象继续递归操作
			obj[key] = filterParams(value);
		}
	});
	return obj;
}
function getType(value){
	return Object.prototype.toString.call(value).slice(8, -1);
}
function equalType(value, constructor) {
	return getType(value) == constructor.name;
}
function optimizeNull(data, model = {}) {
	// 防止model传参类型错误
	if (!equalType(model, Object)) {
		throw new Error('model 需传 Object 类型！');
	}
	// model为空对象：原样返回
	if (!Object.keys(model).length) {
		return data;
	}
	// data非对象：可能是数组或字符串，原样返回，不去默认赋值为 {}
	if (!equalType(data, Object)) {
		return data;
	}
	// 筛选，替换 null、undefined：用传入的 modal 对象处理 data 中的 null、undefined 值
	const obj = JSON.parse(JSON.stringify(data));
	Object.entries(model).map(([key, value]) => {
		const dataValue = obj[key];
		if (Object.is(dataValue, null) || Object.is(dataValue, undefined)) { // 赋值替换或新增
			obj[key] = value;
		} else if (equalType(dataValue, Object)) { // 对象：递归处理，注意 model 里面的对应值也要为对象类型
			obj[key] = optimizeNull(dataValue, model[key]);
		}
	});
	return obj;
}




// 4.方法封装
const dataGroup = ['PUT', 'post', 'PATCH']; // 使用 data 字段传参的类型
const paramsGroup = ['GET']; // 使用 params 字段传参的类型


function axiosAsync(method, url, params = {}) {
	params = filterParams(params);
	return axios({
		method,
		url,
		data:method == 'post'?params:{},
		params:method == 'get'? params : {},
	});
}
const api = {
	root,
	get(url, params) {
		return axiosAsync('get', url, params);
	},
	post(url, params) {
		return axiosAsync('post', url, params);
	},
};


export default {
	...api,
	install(Vue) {
		Object.assign(Vue.prototype, {
			$api: api
		});
	},
};
